import React from 'react'

export default class GMap extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loaded: false
        }
    }

    componentDidMount() {
        this.setState({loaded: true})
    }

    render() {
        const { loaded } = this.state

        const innerHTML = {__html: '<iframe style="left:0;top:0;height:100%;width:100%;position:absolute;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3264.2460573540225!2d-96.40715301997885!3d35.10056626930034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87b47f7cd5c6d333%3A0x9116df48d034ae22!2s801%20OK-48%2C%20Holdenville%2C%20OK%2074848!5e0!3m2!1sen!2sus!4v1608033624702!5m2!1sen!2sus" width="600" height="450" frameborder="1" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>'}

        return (
            <div>
                { loaded && 
                    <div id="gmap" className="rounded" dangerouslySetInnerHTML={innerHTML} />
                }
            </div>
            
        )
    }
}